import { InputComponent } from "@/components";
import { mapActions } from "vuex";

export default {
  name: "ChangePassword",
  components: {
    InputComponent
  },
  data: () => ({
    email: ''
  }),
  methods:{
    ...mapActions("account", {
      forgotPassword: "FORGOT_PASSWORD"
    }),
    nextStep() {
      this.forgotPassword({email: this.email});
    }
  }
};
