<template>
	<div class="c-change-password">
		<p>
			Voer het e-mailadres in dat u hebt gebruikt toen u lid werd en wij sturen u instructies om uw wachtwoord
			opnieuw in te stellen. Om veiligheidsredenen slaan wij uw wachtwoord NIET op. <br />
			U kunt er dus zeker van zijn dat wij uw wachtwoord nooit via e-mail zullen versturen.
		</p>
		<InputComponent v-model="email" input-type="email" label="E-mailadres" autocomplete="email" />
		<button class="c-button" @click="nextStep()">Instructies versturen</button>
	</div>
</template>

<script src="./ChangePassword.controller.js"></script>
<style lang="scss" src="./ChangePassword.styles.scss"></style>
